export const AppStateConstants = {
  LOGGED_IN_USER_DETAILS: "LOGGED_IN_USER_DETAILS",
  CLOSED_TICKET_PAGE_COUNT: "CLOSED_TICKET_PAGE_COUNT",
  OPEN_TICKET_PAGE_COUNT: "OPEN_TICKET_PAGE_COUNT",
  HOLD_TICKET_PAGE_COUNT: "HOLD_TICKET_PAGE_COUNT",
  ON_DEMAND_NEW_BOOKING_PAGE_COUNT: "ON_DEMAND_NEW_BOOKING_PAGE_COUNT",
  DAILY_CLEANING_JOBS_PAGE_SIZE: "DAILY_CLEANING_JOBS_PAGE_SIZE",
  ON_DEMAND_JOBS_PAGE_SIZE: "ON_DEMAND_JOBS_PAGE_SIZE",
  PENDING_JOBS_PAGE_SIZE: "PENDING_JOBS_PAGE_SIZE",
  REPORTED_JOBS_PAGE_SIZE: "REPORTED_JOBS_PAGE_SIZE",
  COMPLAINT_JOBS_PAGE_SIZE: "COMPLAINT_JOBS_PAGE_SIZE",
  ALL_USERS_PAGE_SIZE: "ALL_USERS_PAGE_SIZE",
  OPEN_TICKET_PAGE_SIZE: "OPEN_TICKET_PAGE_SIZE",
  ON_DEMAND_SERVICE_PACKAGE_PAGE_SIZE: "ON_DEMAND_SERVICE_PACKAGE_PAGE_SIZE",
  FILTER_SERVICE_PACKAGES: "FILTER_SERVICE_PACKAGES",
  HOLD_TICKET_PAGE_SIZE: "HOLD_TICKET_PAGE_SIZE",
  CLOSED_TICKET_PAGE_SIZE: "CLOSED_TICKET_PAGE_SIZE",
  PARTNER_TIPS_PAGE_SIZE: " PARTNER_TIPS_PAGE_SIZE",
  SERVICE_PLACES_PAGE_SIZE: "SERVICE_PLACES_PAGE_SIZE",
  SERVICE_PLACES_LOCATIONS_PAGE_SIZE: "SERVICE_PLACES_LOCATIONS_PAGE_SIZE",
  DAILY_CLEANING_SERVICE_PACKAGES_PAGE_SIZE:
    "DAILY_CLEANING_SERVICE_PACKAGES_PAGE_SIZE",
  DAILY_CLEANING_SERVICE_PACKAGES_PAGE_SIZE:
    "DAILY_CLEANING_SERVICE_PACKAGES_PAGE_SIZE",
  CAR_SERVICE_PACKAGES_PAGE_SIZE: "CAR_SERVICE_PACKAGES_PAGE_SIZE",
  BIKE_SERVICE_PACKAGES_PAGE_SIZE: "BIKE_SERVICE_PACKAGES_PAGE_SIZE",
  BIKES_BRANDS_LIST_PAGE_SIZE: "BIKES_BRANDS_LIST_PAGE_SIZE",
  PARTNER_ATTENDANCE_PAGE_SIZE: "PARTNER_ATTENDANCE_PAGE_SIZE",
  CARS_BRANDS_LIST_PAGE_SIZE: " CARS_BRANDS_LIST_PAGE_SIZE",
  PARTNERS_PACKAGE_LIST_PAGE_SIZE: " PARTNERS_PACKAGE_LIST_PAGE_SIZE",
  PARTNERS_BOOKING_LIST_PAGE_SIZE: "PARTNERS_BOOKING_LIST_PAGE_SIZE",
  PARTNER_EVERY_DAY_RATINGS_PAGE_SIZE: "PARTNER_EVERY_DAY_RATINGS_PAGE_SIZE",
  PARTNER_COMBINED_POCKET_HISTORY_PAGE_SIZE:
    "PARTNER_COMBINED_POCKET_HISTORY_PAGE_SIZE",
  ADDONS_PARTNERS_PACKAGE_LIST_PAGE_SIZE:
    "ADDONS_PARTNERS_PACKAGE_LIST_PAGE_SIZE",
  NOT_ATTEMPTED_JOBS_PAGE_NUMBER: "NOT_ATTEMPTED_JOBS_PAGE_NUMBER",
  USER_DETAILS_BOOKINGS_PAGE_SIZE: "USER_DETAILS_BOOKINGS_PAGE_SIZE",
  USER_DETAILS_PAYMENTS_PAGE_SIZE: "USER_DETAILS_PAYMENTS_PAGE_SIZE",
  USER_WALLET_CARD_DETAILS_PAGE_SIZE: "USER_WALLET_CARD_DETAILS_PAGE_SIZE",
  RATING_BY_USER_PAGE_SIZE: "RATING_BY_USER_PAGE_SIZE",
  REFERRALS_PAGE_SIZE: "REFERRALS_PAGE_SIZE",
  ALL_PARTNERS_PAGE_SIZE: "ALL_PARTNERS_PAGE_SIZE",
  PARTNERS_RATING_PAGE_SIZE: "PARTNERS_RATING_PAGE_SIZE",
  PARTNER_TIPS_PAGE_SIZE: "PARTNER_TIPS_PAGE_SIZE",
  ASSIGNED_BOOKINGS_PAGE_SIZE: "ASSIGNED_BOOKINGS_PAGE_SIZE",
  DAILY_CLEANING_INACTIVE_BOOKINGS_PAGE_SIZE:
    "DAILY_CLEANING_INACTIVE_BOOKINGS_PAGE_SIZE",
    DAILY_CLEANING_PAYMENT_PENDING_BOOKINGS_PAGE_SIZE:"DAILY_CLEANING_PAYMENT_PENDING_BOOKINGS_PAGE_SIZE",
    DAILY_CLEANING_INACTIVE_BOOKINGS_PAGE_SIZE:
    "DAILY_CLEANING_INACTIVE_BOOKINGS_PAGE_SIZE",
  DAILY_CLEANING_CANCELLED_BOOKINGS_PAGE_SIZE:
    "DAILY_CLEANING_CANCELLED_BOOKINGS_PAGE_SIZE",
  ON_DEMAND_UNASSIGNED_BOOKING_PAGE_SIZE:
    "ON_DEMAND_UNASSIGNED_BOOKING_PAGE_SIZE",
  ON_DEMNAD_ASSIGNED_BOOKINGS_PAGE_SIZE:
    "ON_DEMNAD_ASSIGNED_BOOKINGS_PAGE_SIZE",
  ON_DEMAND_COMPLETED_BOOKINGS_PAGE_SIZE:
    "ON_DEMAND_COMPLETED_BOOKINGS_PAGE_SIZE",
  ON_DEMAND_CANCELLED_BOOKINGS_PAGE_SIZE:
    "ON_DEMAND_CANCELLED_BOOKINGS_PAGE_SIZE",
  ON_DEMAND_ASSIGNED_BOOKING_PAGE_COUNT:
    "ON_DEMAND_ASSIGNED_BOOKING_PAGE_COUNT",
  ON_DEMAND_UN_ASSIGNED_BOOKING_PAGE_COUNT:
    "ON_DEMAND_UN_ASSIGNED_BOOKING_PAGE_COUNT",
  ON_DEMAND_INACTIVE_BOOKING_PAGE_COUNT:
    "ON_DEMAND_INACTIVE_BOOKING_PAGE_COUNT",
  ON_DEMAND_CANCELED_BOOKING_PAGE_COUNT:
    "ON_DEMAND_CANCELED_BOOKING_PAGE_COUNT",
  ON_DEMAND_NOT_ASSIGNED_BOOKING_PAGE_COUNT:
    "ON_DEMAND_NOT_ASSIGNED_BOOKING_PAGE_COUNT",
  DAILY_CLEANING_NEW_BOOKING_PAGE_COUNT:
    "DAILY_CLEANING_NEW_BOOKING_PAGE_COUNT",
  DAILY_CLEANING_ASSIGNED_BOOKING_PAGE_COUNT:
    "DAILY_CLEANING_ASSIGNED_BOOKING_PAGE_COUNT",
  DAILY_CLEANING_INACTIVE_BOOKING_PAGE_COUNT:
    "DAILY_CLEANING_INACTIVE_BOOKING_PAGE_COUNT",
    DAILY_CLEANING_PAYMENT_PENDING_BOOKING_PAGE_COUNT:"DAILY_CLEANING_PAYMENT_PENDING_BOOKING_PAGE_COUNT",
  ALL_USERS_PAGE_COUNT: "ALL_USERS_PAGE_COUNT",
  ALL_PARTNERS_PAGE_COUNT: "ALL_PARTNERS_PAGE_COUNT",
  NOT_ATTEPMTED_DAILY_CLEANING_JOB_PAGE_NUMBER:
    "NOT_ATTEPMTED_DAILY_CLEANING_JOB_PAGE_NUMBER",
  COMPLETED_DAILY_CLEANING_JOB_PAGE_NUMBER:
    "COMPLETED_DAILY_CLEANING_JOB_PAGE_NUMBER",
  APPROVAL_PENDING_DAILY_CLEANING_JOB_PAGE_NUMBER:
    "APPROVAL_PENDING_DAILY_CLEANING_JOB_PAGE_NUMBER",
  NOT_CLEAN_DAILY_CLEANING_JOB_PAGE_NUMBER:
    "NOT_CLEAN_DAILY_CLEANING_JOB_PAGE_NUMBER",
  NO_VEHICLE_DAILY_CLEANING_JOB_PAGE_NUMBER:
    "NO_VEHICLE_DAILY_CLEANING_JOB_PAGE_NUMBER",
  SCHEDULED_ON_DEMAND_JOB_PAGE_NUMBER: "SCHEDULED_ON_DEMAND_JOB_PAGE_NUMBER",
  STARTED_ON_DEMAND_JOB_PAGE_NUMBER: "STARTED_ON_DEMAND_JOB_PAGE_NUMBER",
  COMPLETED_ON_DEMAND_JOB_PAGE_NUMBER: "COMPLETED_ON_DEMAND_JOB_PAGE_NUMBER",
  APPROVAL_PENDING_ON_DEMAND_JOB_PAGE_NUMBER:
    "APPROVAL_PENDING_ON_DEMAND_JOB_PAGE_NUMBER",
    OPENED_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER:"OPENED_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER",
    HOLD_RESOLUTION_TICKET_PAGE_NUMBER:"HOLD_RESOLUTION_TICKET_PAGE_NUMBER",
    CLOSED_RESOLUTION_TICKET_PAGE_NUMBER:"CLOSED_RESOLUTION_TICKET_PAGE_NUMBER",
  NOT_CLEAN_ON_DEMAND_JOB_PAGE_NUMBER: "NOT_CLEAN_ON_DEMAND_JOB_PAGE_NUMBER",
  NO_VEHICLE_ON_DEMAND_JOB_PAGE_NUMBER: "NO_VEHICLE_ON_DEMAND_JOB_PAGE_NUMBER",
  PARTNER_ATTENDANCE_STARTED_PAGE_NUMBER:
    "PARTNER_ATTENDANCE_STARTED_PAGE_NUMBER",
  PARTNER_ATTENDANCE_PRESENT_PAGE_NUMBER:
    "PARTNER_ATTENDANCE_PRESENT_PAGE_NUMBER",
  PARTNER_ATTENDANCE_ABSENT_PAGE_NUMBER:
    "PARTNER_ATTENDANCE_ABSENT_PAGE_NUMBER",
  PARTNER_ATTENDENCE_SELECTED_TAB: "PARTNER_ATTENDENCE_SELECTED_TAB",
  DAILY_CLEANING_JOB_SELECTED_TAB: "DAILY_CLEANING_JOB_SELECTED_TAB",
  CALL_BACK_REQUEST_TICKET_SELECTED_TAB:"CALL_BACK_REQUEST_TICKET_SELECTED_TAB",
  CALL_BACK_REQUEST_TICKET_PAGE_SIZE:"CALL_BACK_REQUEST_TICKET_PAGE_SIZE",
  CLOSED_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER:"CLOSED_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER",
  HOLD_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER:"HOLD_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER",
  FILTER_CALL_BACK_REQUEST_TICKET_DATA:"FILTER_CALL_BACK_REQUEST_TICKET_DATA",
  OPENED_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER:"OPENED_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER",
  RESOLUTION_TICKET_PAGE_SIZE:"RESOLUTION_TICKET_PAGE_SIZE",
  RESOLUTION_TICKET_SELECTED_TAB:"RESOLUTION_TICKET_SELECTED_TAB",
  FILTER_RESOLUTION_TICKET_DATA:"FILTER_RESOLUTION_TICKET_DATA",
  ON_DEMAND_JOB_SELECTED_TAB: "ON_DEMAND_JOB_SELECTED_TAB",
  REPORTED_DAMAGE_PAGE_NUMBER: "REPORTED_DAMAGE_PAGE_NUMBER",
  COMPLAINT_JOBS_PAGE_NUMBER: "COMPLAINT_JOBS_PAGE_NUMBER",
  SERVICE_PLACES_PAGE_NUMBER: "SERVICE_PLACES_PAGE_NUMBER",
  APARTMENT_DETAILS_SELECTED_TAB: "PARTMENT_DETAILS_SELECTED_TAB",
  SET_CAR_BRANDS_PAGE_NUMBER: "SET_CAR_BRANDS_PAGE_NUMBER",
  SET_AUTO_DEBITS_PAGE_NUMBER: "SET_AUTO_DEBITS_PAGE_NUMBER",
  SET_AUTO_DEBITS_PAGE_SIZE: "SET_AUTO_DEBIT_PAGE_SIZE",
  SET_CAR_MODELS_PAGE_NUMBER: "SET_CAR_MODELS_PAGE_NUMBER",
  SET_CAR_VARIANTS_PAGE_NUMBER: "SET_CAR_VARIANTS_PAGE_NUMBER",
  SERVICE_PACKAGE_PAGE_NUMBER: "SERVICE_PACKAGE_PAGE_NUMBER",
  SET_BIKE_BRANDS_PAGE_NUMBER: "SET_BIKE_BRANDS_PAGE_NUMBER",
  SET_BIKE_MODELS_PAGE_NUMBER: "SET_BIKE_MODELS_PAGE_NUMBER",
  SET_BIKE_VARIANTS_PAGE_NUMBER: "SET_BIKE_VARIANTS_PAGE_NUMBER",
  SET_BUSINESS_PAGE_PAGINATION_NUMBER: "SET_BUSINESS_PAGE_PAGINATION_NUMBER",
  FILTER_AT_NEW_DAILY_CLEANING_BOOKINGS:
    "FILTER_AT_NEW_DAILY_CLEANING_BOOKINGS",
  ON_DEMAND_NEW_BOOKING_COUNT: "ON_DEMAND_NEW_BOOKING_COUNT",
  REPORTED_JOBS_APPROVED_PAGE_NUMBER: "REPORTED_JOBS_APPROVED_PAGE_NUMBER",
  REPORTED_JOBS_NOT_APPROVED_PAGE_NUMBER:
    "REPORTED_JOBS_NOT_APPROVED_PAGE_NUMBER",
  CUSTOMER_CLAIMED_REFUNDS: "CUSTOMER_CLAIMED_REFUNDS",
  FREE_TRIAL_ACTIVE_BOOKINGS_PAGE_NUM: "FREE_TRIAL_ACTIVE_BOOKINGS_PAGE_NUM",
  FREE_TRIAL_NOT_CONVERTED_BOOKINGS_PAGE_NUM:
    "FREE_TRIAL_NOT_CONVERTED_BOOKINGS_PAGE_NUM",
  FREE_TRIAL_CONVERTED_BOOKINGS_PAGE_NUM:
    "FREE_TRIAL_CONVERTED_BOOKINGS_PAGE_NUM",
  FILTER_DAILY_CLEANING_DATA: "FILTER_DAILY_CLEANING_DATA",
  FILTER_PARTNERS_DATA: "FILTER_PARTNER_DATA",
  FILTER_ASSIGNED_BOOKINGS: "FILTER_ASSIGNED_BOOKINGS",
  FILTER_DAILY_CLEANING_REFUNDS: "FILTER_DAILY_CLEANING_REFUNDS",
  REFUND_DATA_SUMMARY: "REFUND_DATA_SUMMARY",
  SET_NOTIFICATION_PAGE_NUMBER: "SET_NOTIFICATION_PAGE_NUMBER",
  FILTER_NOTIFICATION_DATA: "FILTER_NOTIFICATION_DATA",
  NOTIFICATION_FILTER_PAYLOAD: "NOTIFICATION_FILTER_PAYLOAD",
  OPENED_RESOLUTION_TICKET_PAGE_NUMBER:"OPENED_RESOLUTION_TICKET_PAGE_NUMBER",
  CLOSED_REPORTED_TICKET_PAGE_NUMBER:"CLOSED_REPORTED_TICKET_PAGE_NUMBER",
  REPORTED_TICKET_PAGE_SIZE:"REPORTED_TICKET_PAGE_SIZE",
  REPORTED_TICKET_SELECTED_TAB:"REPORTED_TICKET_SELECTED_TAB",
  FILTER_REPORTED_TICKET_DATA:"FILTER_REPORTED_TICKET_DATA",
  OPENED_REPORTED_TICKET_PAGE_NUMBER:"OPENED_REPORTED_TICKET_PAGE_NUMBER",
  ACTIVE_PARTNERS_PAGE_NUMBER:"ACTIVE_PARTNERS_PAGE_NUMBER",
  INACTIVE_PARTNERS_PAGE_NUMBER:"INACTIVE_PARTNERS_PAGE_NUMBER",
  ALL_PARTNERS_PAGE_SIZE:"ALL_PARTNERS_PAGE_SIZE",
  ALL_PARTNERS_SELECTED_TAB:"ALL_PARTNERS_SELECTED_TAB",
  ALL_USERS_PAGE_SIZE: "ALL_USERS_PAGE_SIZE",
  ALL_USER_REFERRAL_PAGE_COUNT:"ALL_USER_REFERRAL_PAGE_COUNT",
  GLOBAL_TABLE_DATA: "GLOBAL_TABLE_DATA",
  USERS_PAGE_COUNT: "USERS_PAGE_COUNT",
  LOGOUT_USER: "LOGOUT_USER",
  AUTO_DEBIT_FILTERS: "AUTO_DEBIT_FILTERS",
  AUTO_DEBIT_USERS_COUNT: "AUTO_DEBIT_USERS_COUNT",
  FILTER_POCKET_HISTORY: "FILTER_POCKET_HISTORY",
}
