import fetch from "isomorphic-fetch"
const PROTOCOL = window.location.protocol
//const HOST = 'localhost:8000';
//const API_URL = `${PROTOCOL}//${HOST}`;
// const API_URL = `https://test-api.honc.io`
// const API_URL = `https://devapi.honc.io`
const API_URL = `https://api.honc.io`
// const API_URL = `http://localhost:8000`;
// const API_URL = 'http://192.168.1.182:8000';
// const API_URL = "http://localhost:8000" // localhost Arjun's office
// const API_URL = "http://192.168.0.100:5000" // localhost Arjun's Flat
// const API_URL = "http://13.233.193.184:5200"
// const API_URL = "http://192.168.101.215:5200"
// const API_URL = "http://192.168.101.104:5200"

export default function callApiApp(endpoint, method = "get", body) {
  let token = window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : ""
  let headers = {}
  headers["content-type"] = "application/json"
  if (token && token !== "") {
    headers.Authorization = `Bearer ${token}`
  }
  console.log(`${API_URL}/${endpoint}`)
  return fetch(`${API_URL}/${endpoint}`, {
    headers: headers,
    method,
    body: JSON.stringify(body),
  })
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json)
      }
      return json
    })
    .then(
      (response) => response,
      (error) => error
    )
}
